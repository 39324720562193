import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store/index";
import hideMenu from "./api/hideBarMenu";
import Toast from "./utils/toast";
import "normalize.css";
import "lib-flexible";
import "mint-ui/lib/style.css";
import Icon from "./views/components/Icon.vue";
// import Vconsole from 'vconsole'
import "./main.css";
// eslint-disable-next-line no-unused-vars
// const _vconsole = new Vconsole()

import { Popup, Picker, DatetimePicker } from "vant";

Vue.use(Popup);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.config.productionTip = false;
Vue.use(Toast);
Vue.component("Icon", Icon);

const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
const req = require.context("./assets/svg-sprite", false, /\.svg$/);
requireAll(req);

router.beforeEach((to, from, next) => {
  const showBar = !hideMenu.includes(to.name);
  console.log(to.name, showBar);

  store.commit("showBar", showBar);
  next();
});

var token1 = window.location.hash.split("token=");
token1 = token1[1];
console.log(token1);
if (token1) {
  var token = token1.split("&message=");
}

if (token && token[0]) {
  Vue.prototype.$token = token[0];
  localStorage.setItem("message", token[1]);
}

new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");
