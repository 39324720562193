import http from "./http";
import store from "@/store/index";

// for local programming
// let host = process.env.NODE_ENV === "development" ? "https://dev.offer.sibaiji.cn" : "https://offer.sibaiji.cn";
// // for webview url request
// if (window.location.href.includes('dev.')) {
//   host = "https://dev.offer.sibaiji.cn";
// }
let host = process.env.NODE_ENV === "development" ? "/offerApi" : "/offerApi";
// for webview url request
if (window.location.href.includes("dev.")) {
  host = "/offerApi";
}
host = "https://ap.sqjoy.com/offerApi";
//userInfo
export async function getUserInfo() {
  let { data } = await http.post(`${host}/offer/auth/userInfo`);
  store.commit("update", { data: data.data, name: "userinfo" });
  return data;
}
