<template>
  <svg :class="`icon-sprite icon-sprite-${name}`" @click="$emit('click')">
    <use :xlink:href="`#icon-${name}`"></use>
  </svg>
</template>

<script>
export default {
  name: "Icon",
  props: {
    name: String,
  },
};
</script>

<style scoped lang="stylus">
.icon-sprite {
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: -0.15em;
  overflow: hidden;
  font-size: inherit;
  line-height: inherit;
}
</style>
