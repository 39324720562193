function px2rem (px) {
  return `${px / (375 / 16)}rem`
}

class Toast {
  constructor (options) {
    this.message = options.message
    this.color = options.color || '#ffffff'
    this.backgroundColor = options.backgroundColor || 'rgba(0, 0, 0, 0.8)'
    this.duration = options.duration || 2000
    this.el = null
    this.init()
    this._timer = setTimeout(() => {
      this.destroy()
    }, this.duration)
  }

  init () {
    // let toastRoot = document.querySelector('.toast-list')
    // if (!toastRoot) {
    //   toastRoot = document.createElement('div')
    //   toastRoot.classList.add('toast-list')
    //   toastRoot.style.cssText = `
    //     position: absolute;
    //     top:50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);`
    //   document.body.appendChild(toastRoot)
    // }
    this.el = document.createElement('div')
    this.el.style.cssText = `
      padding: ${px2rem(10)} ${px2rem(8)};
      max-width: ${px2rem(224)};
      border-radius: ${px2rem(4)};
      font-size: ${px2rem(12)};
      line-height: ${px2rem(18)};
      text-align: center;
      box-sizing: content-box;
      color: ${this.color};
      background-color: ${this.backgroundColor};
      font-family: PingFangSC-Regular, Arial, sans-serif;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99999;`
    this.el.textContent = this.message
    document.body.appendChild(this.el)
  }

  destroy () {
    clearTimeout(this._timer)
    this.el.remove()
  }
}

function toast (message, options) {
  let option = {
    message
  }
  if (typeof message === 'string') {
    if (Object.prototype.toString.call(options) === '[object Object]') {
      option = Object.assign(options, option)
    }
  } else if (Object.prototype.toString.call(message) === '[object Object]') {
    option = message
  }
  new Toast(option)
}

export default {
  install (Vue) {
    Vue.prototype.$toast = toast
  }
}