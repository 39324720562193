<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getUserInfo } from "./api/common";
import Vue from "vue";
export default {
  name: "App",
  async created() {
    let res = await getUserInfo();
    if (res && res.data && res.data.linkId) {
      Vue.prototype.$linkId = res.data.linkId;
      // Vue.prototype.$linkId = 825;
    }
  },
  mounted() {},
};
</script>
<style lang="stylus">
#app {
  font-family: -apple-system, 'Noto Sans', 'Helvetica Neue', Helvetica, 'Nimbus Sans L', Arial, 'Liberation Sans', 'PingFang SC', 'Hiragino Sans GB', 'Noto Sans CJK SC', 'Source Han Sans SC', 'Source Han Sans CN', 'Microsoft YaHei', 'Wenquanyi Micro Hei', 'WenQuanYi Zen Hei', 'ST Heiti', SimHei, 'WenQuanYi Zen Hei Sharp', sans-serif;
  min-height: 100vh;
  margin: 0 auto;
  background: #F3F3F3;
  max-width: 100vw;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

/* 禁用iPhone中Safari的字号自动调整 */
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

/* 去除iPhone中默认的input样式 */
input { /* -webkit-appearance:none; */
  resize: none;
}

* { /* For some Androids */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

p {
  margin: 0;
  padding: 0;
}
</style>