// 隐藏底部tabbar的路由 name
const menu = [
  "mentor-history-byid", // 单个课程的课时记录
  "learner-class-detail", // 学生我的-加入班级-详情
  "learner-join-success", // 学生我的-加入班级-成功

  "learner-message-messageDetail", //学生-消息列表详情
  "learner-leave", //学生-请假
  "learner-timeSeting", //学生-空闲时间
  "mentor-schedule-seting", // 导师 我的-排课设置页
  "mentor-schedule", // 导师 我的- 我的课程页

  "mentor-message-messageDetail", //导师-消息列表详情
  "mentor-leave", //导师-请假
];

export default menu;
