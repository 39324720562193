import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  routes: [
    // 导师端
    {
      path: "/mentor",
      name: "mentor",
      component: () => import("./views/mentor/index.vue"),
      children: [
        // 我的页面
        {
          path: "mine",
          name: "mentor-mine",
          component: () => import("./views/mentor/mine/index.vue"),
        },
        {
          path: "schedule-seting",
          name: "mentor-schedule-seting",
          component: () => import("./views/mentor/schedule/seting.vue"),
        },
        {
          path: "schedule",
          name: "mentor-schedule",
          component: () => import("./views/mentor/schedule/index.vue"),
        },
        {
          path: "seting",
          name: "mentor-seting",
          component: () => import("./views/mentor/seting.vue"),
        },
        // 课时记录
        {
          path: "history/detail",
          name: "mentor-history-byid",
          component: () => import("./views/mentor/history/historyById.vue"),
        },
        {
          path: "history",
          name: "mentor-history",
          component: () => import("./views/mentor/history/index.vue"),
        },
        {
          path: "calendar",
          name: "mentor-calendar",
          component: () => import("./views/mentor/calendar/index.vue"),
        },
        // {
        //   path: "message",
        //   name: "mentor-message",
        //   component: () => import("./views/mentor/message/index.vue"),
        // },
        {
          path: "message",
          name: "mentor-message",
          component: () => import("./views/mentor/message/list.vue"),
        },
        {
          path: "message/MessageDetail",
          name: "mentor-message-messageDetail",
          component: () => import("./views/mentor/message/MessageDetail.vue"),
        },
        {
          path: "leave",
          name: "mentor-leave",
          component: () => import("./views/mentor/calendar/Leave.vue"),
        },
      ],
    },
    // 学生端
    {
      path: "/learner",
      name: "learner",
      component: () => import("./views/learner/index.vue"),
      children: [
        // 我的页面
        {
          path: "mine",
          name: "learner-mine",
          component: () => import("./views/learner/mine/index.vue"),
        },
        {
          path: "seting",
          name: "learner-seting",
          component: () => import("./views/learner/seting.vue"),
        },
        {
          path: "class",
          name: "learner-class",
          component: () => import("./views/learner/class/index.vue"),
        },
        {
          path: "class/detail",
          name: "learner-class-detail",
          component: () => import("./views/learner/class/detail.vue"),
        },
        {
          path: "class/success",
          name: "learner-join-success",
          component: () => import("./views/learner/class/success.vue"),
        },
        // 课时记录
        {
          path: "history",
          name: "learner-history",
          component: () => import("./views/learner/history/index.vue"),
        },
        {
          path: "history/detail",
          name: "mentor-history-byid",
          component: () => import("./views/learner/history/historyById.vue"),
        },
        {
          path: "calendar",
          name: "learner-calendar",
          component: () => import("./views/learner/calendar/index.vue"),
        },
        // {
        //   path: "message",
        //   name: "learner-message",
        //   component: () => import("./views/learner/message/index.vue"),
        // },
        {
          path: "message",
          name: "learner-message",
          component: () => import("./views/learner/message/list.vue"),
        },
        {
          path: "message/MessageDetail",
          name: "learner-message-messageDetail",
          component: () => import("./views/learner/message/MessageDetail.vue"),
        },
        {
          path: "leave",
          name: "learner-leave",
          component: () => import("./views/learner/calendar/Leave.vue"),
        },
        {
          path: "timeSeting",
          name: "learner-timeSeting",
          component: () => import("./views/learner/mine/timeSeting.vue"),
        },
      ],
    },
    // 预约
    {
      path: "/reserve",
      name: "learner-reserve",
      component: () => import("./views/learner/reserve/index.vue"),
    },
    {
      path: "/reserveDetial",
      name: "learner-reserve-detail",
      component: () => import("./views/learner/reserve/detail.vue"),
    },
    // 公共页面
    {
      path: "/about",
      name: "sbj-about",
      component: () => import("./views/about.vue"),
    },
    {
      path: "/protocol",
      name: "sbj-protocol",
      component: () => import("./views/protocol.vue"),
    },
    {
      path: "*",
      redirect: () => {
        return "/mentor";
      },
    },
  ],
});

export default router;
