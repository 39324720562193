import axios from "axios";
import qs from "qs";
import Vue from "vue";

const http = axios.create({
  timeout: 30000,
});
console.log(process.env.NODE_ENV);
http.interceptors.request.use(function(config) {
  config.withCredentials = true;

  let token = Vue.prototype.$token;
  // let token = "a0f529b365e04b38852a7dcc0b2eeceb"; //t
  // let token = "9d1ba3c739f94544b1863e6eca8dc8fa"; //test t
  // let token = "9d1ba3c739f94544b1863e6eca8dc8fa"; //s
  // let token = "2828dd276e234ec0b50d0e401bb72dab"; //test s
  config.headers = {
    ...config.headers,
    token,
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  };
  if (config["json"]) {
    config.responseType = "json";
    config.headers["Content-type"] = "application/json";
    delete config["json"];
    return config;
  }
  if (config.method.toLowerCase() === "post") {
    let data = config.data || {};
    config.data = qs.stringify(data);

    config.headers = {
      ...config.headers,
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    };
  }
  return config;
});

http.interceptors.response.use(
  function(response) {
    // console.log(wx);
    if (response.code === 200 && response.data.code === 200) {
      return response;
      // 1028 --> 不能排过去时间的课程，由于已经置灰，不需要 toast 提醒
    } else if (response.data.code === 1028) {
      return response;
    } else if (response.data.code === 1003) {
      if (response.data.msg) {
        Vue.prototype.$toast(response.data.msg);
      }
      setTimeout(() => {
        wx.miniProgram.redirectTo({ url: "/pages/login/index?seting=1" });
      }, 500);
    } else {
      if (response.data.msg) {
        Vue.prototype.$toast(response.data.msg);
      }
      return response;
    }
  },
  function(error) {
    if (error.message.indexOf("timeout") !== -1) {
      return Promise.reject(new Error("当前环境网络过慢，请求超时!"));
    }
    return Promise.reject(error);
  }
);

export default http;
