import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showBar: false,
    timeQuery: {},
    userinfo: {},
  },
  mutations: {
    showBar(state, payload) {
      state.showBar = payload;
    },
    update(context, { data, name }) {
      this.state[name] = data;
    },
  },
  actions: {
    // async actTimeQuery(context, params) {
    //   const res = await getTimeQuery(params)
    //   context.commit('timeQuery', res)
    // },
  },
  getters: {},
});
